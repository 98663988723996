import Layout from '../../components/layouts/freshest/FreshestLayout';
import { Component404 } from '@mdc/ui';

const Template404 = () => {
    return (
        <Layout pageTitle="404" pageDescription={'Not found'}>
            <Component404 />
        </Layout>
    );
};

export default Template404;
